import React from "react"
import YouTube from "react-youtube"
import el1 from "assets/imgs/landing/Elements/Ellipse1.png"
import el2 from "assets/imgs/landing/Elements/Ellipse2.png"
import el3 from "assets/imgs/landing/Elements/Ellipse3.png"
import el4 from "assets/imgs/landing/Elements/Ellipse4.png"
import useWindow from "components/hooks/useWindow"
import PropTypes from "prop-types"
import { useCustomDevice } from "components/hooks/useCustomDevice"

function Video({ data }) {
  const { isMobile } = useWindow()

  const opts = {
    width: isMobile ? "310px" : "800px",
    height: isMobile ? "300px" : "450px",
    playerVars: {
      autoplay: 1,
    },
  }

  const web = (
    <div className="container pb-4  sm:p-0">
      <div className="mx-5 sm:mx-0">
        <div className="video_banner">
          <h4 className="px-3">Let’s Check it out!</h4>
          <div className="react-player d-flex-center-center">
            <div className="react-player-video">
              <YouTube
                videoId={data?.videoId}
                id={data?.videoId}
                opts={opts}
                className="d-flex"
                iframeClassName={isMobile && "w-100"}
                // className={string}
                // style={object}
                // title={string}
                // loading={string}
                // onReady={func}
                // onPlay={func}
                // onPause={func}
                // onEnd={func}
                // onError={func}
                // onStateChange={func}
                // onPlaybackRateChange={func}
                // onPlaybackQualityChange={func}
              />
            </div>
            {/* <div className="react-player-video">
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${data?.videoId}`}
                controls={false}
                light={false}
                autoPlay={true}
                width={isMobile ? "310px" : "800px"}
                height={isMobile ? "300px" : "450px"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default Video

Video.propTypes = {
  data: PropTypes.any,
}
